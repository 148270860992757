import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Drawer, CssBaseline, AppBar, Toolbar, List, Typography, Divider, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import ApiIcon from '@mui/icons-material/Api';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';
import TokenManager from './sections/TokenManager';
import PollManager from './sections/PollManager';
import ProfileManager from './sections/ProfileManager';


const drawerWidth = 240;

const Dashboard = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {

        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedMenu, setSelectedMenu] = React.useState(1);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuClick = (menuItem) => {
        setSelectedMenu(menuItem);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    }

    const renderContent = () => {
        switch (selectedMenu) {
            case 1:
                return <ProfileManager></ProfileManager>
            case 2:
                return <TokenManager></TokenManager>
            case 3:
                return <PollManager></PollManager>
            case 4:
                return handleLogout()
            default:
                return <Typography variant="h4">Welcome to the app!</Typography>;
        }
    };

    // The items for the menu on the left
    const drawer = (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Menu
                </Typography>
            </Toolbar>
            <Divider />
            <List>
                <ListItem key={1} onClick={() => handleMenuClick(1)}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Meu Perfil'} sx={{cursor: 'pointer'}} />
                </ListItem>
                <ListItem key={2} onClick={() => handleMenuClick(2)}>
                    <ListItemIcon>
                        <ApiIcon />
                    </ListItemIcon>
                    <ListItemText primary={'API Tokens'} sx={{cursor: 'pointer'}} />
                </ListItem>
                <ListItem key={3} onClick={() => handleMenuClick(3)}>
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Eleições 2024'} sx={{cursor: 'pointer'}} />
                </ListItem>
                <Divider />
                <ListItem key={4} onClick={() => handleMenuClick(4)}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Sair'} sx={{cursor: 'pointer'}} />
                </ListItem>
            </List>
            <Divider />
        </div>
    );

    return (
        <Box sx={{ display: 'flex', border: 0 }} >
            <CssBaseline />

            {/* Top AppBar */}
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        PollingData Store
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Left Drawer/Menu */}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* Mobile drawer */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>

                {/* Permanent drawer for larger screens */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            {/* Main content area */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 1,
                    border: 0,
                }}
            >
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>

    );
};

export default Dashboard;







import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import config from '../../config';
import { SpinnerRoundOutlined } from 'spinners-react';
import { useSearchParams } from "react-router-dom";

const ProfileManager = () => {

    const accessToken = localStorage.getItem('token');
    const [userAuthenticated, setUserAuthenticated] = useState(null);
    const [totalPayments, setTotalPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [statusPayment, setStatusPayment] = useState(null);
    const [checkoutClicked, setCheckoutClicked] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const navigate = useNavigate();


    useEffect(() => {

        const init = () => {
            setIsLoading(true);
            axios.get(`${config.apiStoreBaseUrl}/api/auth/getuser`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                    }
                })
                .then(response => {
                    setUserAuthenticated(response.data);
                })
                .catch(error => {
                    if (error.status === 401) {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }

                    console.error("Error fetching tokens:", error);
                });


            axios.get(`${config.apiStoreBaseUrl}/api/billing/getuserpayments`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                    }
                })
                .then(response => {
                    setIsLoading(false);
                    setTotalPayments(response.data);
                })
                .catch(error => {
                    if (error.status === 401) {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }

                    console.error("Error fetching tokens:", error);
                });

            setStatusPayment(searchParams.get('status'));
            if (searchParams.get('payment_id')) {
                const paramsObject = Object.fromEntries(searchParams.entries());
                savePaymentReceived(paramsObject);
            }



        }

        init();

    }, [accessToken, navigate, searchParams]);

    const moneyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const refreshUserPayments = () => {
        setIsLoading(true);
        axios.get(`${config.apiStoreBaseUrl}/api/billing/getuserpayments`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                }
            })
            .then(response => {
                setIsLoading(false);
                setTotalPayments(response.data);

            })
            .catch(error => {
                setIsLoading(false);
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }

                console.error("Error fetching tokens:", error);
            });
    }


    const savePaymentReceived = (params) => {

        try {
            const body = params;

            const conf = {
                method: 'post',
                url: `${config.apiStoreBaseUrl}/api/billing/paymentreceived`,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: body
            };

            axios.request(conf)
                .then((response) => {
                    if (response.status === 200) {
                        refreshUserPayments();
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

        } catch (error) {
            console.error('Save payment failed', error);
        }

    }

    const goToCheckout = () => {
        setCheckoutClicked(true);
        try {
            setIsLoading(true);

            const body = { quantity: 1, price: 19.90, title: "PollingData Token", id: 1 };

            const conf = {
                method: 'post',
                url: `${config.apiStoreBaseUrl}/api/billing/createpreference`,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: body
            };

            axios.request(conf)
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        const initPoint = response.data.init_point;
                        console.log(response.data.init_point);
                        if (initPoint) {
                            window.location.href = initPoint;
                        }
                    }
                    else {
                        setIsLoading(false);
                        setCheckoutClicked(false);
                    }

                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                });

        } catch (error) {
            console.error('Create Preference failed', error);
        }

    }


    const renderSpinner = () => {
        if (isLoading) {
            return (
                <div>
                    <SpinnerRoundOutlined style={{ position: 'absolute', top: '50%', left: '50%' }} сolor='#009EE3' />
                </div>
            )
        }
    }

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);  // Reset to first page when rows per page change
    };

    const paginatedData = totalPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    return (
        <div>
            {renderSpinner()}
            {checkoutClicked ?
                <Box component="section" sx={{ p: 2 }}>
                    <Typography>Redirecionando para o pagamento...</Typography>
                </Box>
                :
                null
            }
            {!checkoutClicked ?
                <Box component="section" sx={{ p: 2 }}>


                    {userAuthenticated ?
                        <Box sx={{ p: 2, border: '1px dashed grey' }}>
                            <Typography variant="h5">Olá {userAuthenticated.user.name}!</Typography>
                        </Box>
                        :
                        null
                    }

                    {statusPayment === 'approved' ?
                        <Box sx={{ p: 2 }}>
                            <Typography color='green' fontSize={22}>Pagamento realizado com sucesso!</Typography>
                        </Box>
                        :
                        null
                    }


                    <Box sx={{ p: 2 }}>
                        <Box sx={{ p: 4 }}>
                            <Button variant="contained" onClick={goToCheckout}>Comprar Token</Button>
                            <Typography fontSize={10}>* Você será redirecionado para o site do Mercado Pago.</Typography>
                            <Typography fontSize={10}>* Após confirmação do pagamento, você será redirecionado de volta a esta página.</Typography>
                        </Box>
                        {paginatedData.length > 0 ?
                            <Box sx={{ p: 2 }}>
                                <Typography>Compras de Tokens</Typography>
                            </Box>
                            :
                            null
                        }
                        {paginatedData.length > 0 ?
                            <Paper>
                                <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
                                    <Table sx={{ maxWidth: '100%' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Data</TableCell>
                                                <TableCell>Produto</TableCell>
                                                <TableCell align="center">Valor Pago</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                <TableCell align="center">URL para Pagamento</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paginatedData.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {new Date(row.created).toLocaleString('pt-BR', {
                                                            year: 'numeric',
                                                            month: 'short',  // You can use 'short' for a 3-letter month
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: false    // To display in 12-hour format with AM/PM
                                                        })}
                                                    </TableCell>
                                                    <TableCell>{row.id_product}</TableCell>
                                                    <TableCell align="center">{moneyFormatter.format(row.amount)}</TableCell>
                                                    <TableCell align="center">{row.payment_status === 'approved' ? 'Aprovado' : row.payment_status === 'null' || !row.payment_status ? 'Aguardando pagamento' : row.payment_status === 'rejected' ? 'Rejeitado' : !row.payment_status}</TableCell>
                                                    <TableCell align="center">{row.payment_status === 'approved' ? '' : <a href={row.url_mp_preference}>Pagar</a>}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalPayments.length}           // Total number of rows
                                    page={page}                       // Current page number
                                    onPageChange={handleChangePage}   // Function to change the page
                                    rowsPerPage={rowsPerPage}         // Rows per page
                                    onRowsPerPageChange={handleChangeRowsPerPage} // Function to change rows per page
                                    rowsPerPageOptions={[5, 10, 25]}  // Available options for rows per page
                                />
                            </Paper>
                            :
                            null
                        }
                    </Box>
                </Box>
                :
                null
            }
        </div>
    )


}

export default ProfileManager;
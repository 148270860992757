import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, FormControl, TextField, FormLabel, InputLabel, Select, MenuItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import config from '../../config';
import { SpinnerRoundOutlined } from 'spinners-react';
import { CSVLink } from 'react-csv';


const PollManager = () => {


    const [selectedState, setSelectedState] = React.useState('');
    const [selectedCity, setSelectedCity] = React.useState('');
    const [selectedToken, setSelectedToken] = React.useState('');
    const [states, setStates] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [candidates, setCandidates] = React.useState([]);
    const [datesPrev, setDatesPrev] = React.useState([]);
    const [chartSeries, setChartSeries] = React.useState([]);
    const [poll, setPoll] = React.useState([]);
    const [apiToken, setAPIToken] = useState(null);
    const [userTokens, setUserTokens] = useState([]);
    const [initPoll, setInitPoll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();




    const getAvailableStates = (apiToken) => {
        setIsLoading(true);
        axios.get(`${config.apiClientBaseUrl}/api/poll/estadosdisponiveis`,
            {
                headers: {
                    'Authorization': `Bearer ${apiToken}` // Pass token in the Authorization header
                }
            })
            .then(response => {
                setIsLoading(false);

                setStates(response.data);
                setInitPoll(true);
            })
            .catch(error => {
                setIsLoading(false);
                setSelectedCity(null);
                setSelectedState(null);
                if (error.status === 401) {
                    //localStorage.removeItem('token');
                    //navigate('/login');
                }

                console.error("Error fetching states:", error);
            });
    }

    const getCitiesByState = (selectedState) => {
        setIsLoading(true);
        if (selectedState && apiToken) {
            axios.post(`${config.apiClientBaseUrl}/api/poll/cidadesdisponiveis`,
                {
                    selectedState: selectedState
                },
                {
                    headers: {
                        'Authorization': `Bearer ${apiToken}` // Pass token in the Authorization header
                    },
                })
                .then(response => {
                    setIsLoading(false);
                    setCities(response.data);
                })
                .catch(error => {
                    setIsLoading(false);
                    if (error.status === 401) {
                        //localStorage.removeItem('token');
                        //navigate('/login');
                    }

                    console.error("Error fetching cities:", error);
                });
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        const getUserTokens = () => {
            if (!accessToken)
                return;


            setIsLoading(true);
            axios.get(`${config.apiStoreBaseUrl}/api/token/getusertokens`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                    }
                })
                .then(response => {
                    setIsLoading(false);
                    setUserTokens(response.data);
                })
                .catch(error => {
                    setIsLoading(false);
                    if (error.status === 401) {
                        localStorage.removeItem('token');
                        navigate('/login');
                    }

                    console.error("Error fetching tokens:", error);
                });
        }

        getUserTokens();

    }, [navigate]);

    const prepareChartData = (poll) => {

        const candidates = poll.reduce((p, value) => {

            if (!p.includes(value.candidato)) {
                p.push(value.candidato);
            }
            return p;
        }, []);

        setCandidates(candidates);


        const datesPrev = poll.reduce((p, value) => {

            if (!p.includes(value['data.previsao'])) {
                p.push(value['data.previsao']);
            }
            return p;
        }, []);

        setDatesPrev(datesPrev);

        const COLORS = ['#8884d8', '#444444', '#ffc658', '#ff8042', '#00C50F', '#FFBB90', '#888450', '#888490', '#888fff', '#000440', '#1114d8', '#1884a8', '#003000'];
        const chartEstimateSeries = [];

        const cdata = [];
        candidates.map((c) => {
            const tdata = [];
            datesPrev.map((d) => {
                const searchEstimate = poll.find(p =>
                    p.candidato === c &&
                    p['data.previsao'] === d &&
                    p.tipo_estimativa === 'voto' &&
                    p.turno === 1
                );
                tdata.push(searchEstimate.estimativa);
            });

            cdata.push({
                candidate: c,
                data: tdata
            });
        });

        cdata.map((cd, index) => {
            chartEstimateSeries.push({
                data: cd.data,
                label: cd.candidate,
                id: cd.candidate,
                color: COLORS[index]
            })
        })

        setChartSeries(chartEstimateSeries);


    }

    const handleSubmit = async (event) => {

        if (initPoll) {
            event.preventDefault();
            if (!validateInputs()) {
                return;
            }

            try {

                setIsLoading(true);
                axios.post(`${config.apiClientBaseUrl}/api/poll/porcidade`,
                    {
                        estado: selectedState,
                        cidade: selectedCity
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${apiToken}` // Pass token in the Authorization header
                        },
                    })
                    .then(response => {
                        setPoll(response.data);
                        setIsLoading(false);
                        prepareChartData(response.data);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        if (error.status === 401) {
                            //localStorage.removeItem('token');
                            //navigate('/login');
                        }

                        console.error("Error fetching poll:", error);
                    });


            } catch (error) {
                console.error('Erro fetching poll', error);
                setIsLoading(false);
            }
        }
        else {
            getAvailableStates();
        }
    };

    const handleSelectToken = (event) => {
        const selectedToken = event.target.value;
        setAPIToken(selectedToken);
        setSelectedToken(selectedToken);
        getAvailableStates(selectedToken);

    }

    const handleSelectState = (event) => {
        const selectedState = event.target.value;
        setSelectedCity(null);
        setPoll([]);
        setSelectedState(selectedState);
        getCitiesByState(selectedState);
        setSelectedCity(""); // Reset the selected city when a new state is chosen
    };


    const handleSelectCity = (event) => {
        setSelectedCity(event.target.value);
    }



    const validateInputs = () => {
        //const apiToken = document.getElementById('apiToken');

        let isValid = true;

        /*
        if (!apiToken.value || apiToken.value.length < 6) {
            setAPITokenError(true);
            setAPITokenErrorMessage('Cole seu token de API aqui.');
            isValid = false;
        } else {
            setAPITokenError(false);
            setAPITokenErrorMessage('');
            setAPIToken(apiToken.value);
        }
            */

        return isValid;
    };

    const renderSpinner = () => {
        if (isLoading) {
            return (
                <div>
                    <SpinnerRoundOutlined style={{ position: 'absolute', top: '50%', left: '50%' }} сolor='#009EE3' />
                </div>
            )
        }
    }

    const CSVHeaders = [
        { label: 'Candidato', key: 'candidato' },
        { label: 'Partido', key: 'partido' },
        { label: 'Pleito', key: 'pleito' },
        { label: 'Fonte', key: 'fonte_estimativa' },
        { label: 'Tipo', key: 'tipo_estimativa' },
        { label: 'Data da Previsão', key: 'data.previsao' },
        { label: 'ESTIMATIVA', key: 'estimativa' }
    ];


    return (
        <div>
            {renderSpinner()}
            <Box component="section" sx={{ p: 2 }}>
                {
                    isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh', // Full height center alignment
                            }}
                        >
                        </Box>
                    ) : (
                        <div>
                            <Box sx={{ p: 2, border: '1px dashed grey', marginBottom: 2 }}>
                                <Typography variant="h5">Eleições 2024 - Prefeitos</Typography>
                            </Box>
                            <Box sx={{ p: 2, width: 500 }}>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        gap: 2,
                                    }}
                                >

                                    {!selectedToken ?
                                        <FormControl>
                                            <InputLabel id="token-select-label">Token</InputLabel>
                                            <Select
                                                labelId="token-select-label"
                                                id="token-select"
                                                label="Token"
                                                value={selectedToken}
                                                onChange={handleSelectToken}
                                            >
                                                {userTokens.map((token, index) => (
                                                    <MenuItem key={index} value={token.token}>
                                                        {token.token}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        :
                                        null
                                    }

                                    {initPoll && (
                                        <FormControl>
                                            <InputLabel id="state-select-label">Estado</InputLabel>
                                            <Select
                                                labelId="state-select-label"
                                                id="state-select"
                                                label="Estado"
                                                value={selectedState}
                                                onChange={handleSelectState}
                                            >
                                                {states.map((state, index) => (
                                                    <MenuItem key={index} value={state.uf}>
                                                        {state.uf}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>)
                                    }



                                    {selectedState && (
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Cidade</InputLabel>
                                            <Select
                                                labelId="city-select-label"
                                                id="city-select"
                                                label="Cidade"
                                                value={selectedCity}
                                                onChange={handleSelectCity}
                                            >
                                                {cities.map((city) => (
                                                    <MenuItem key={city.cityid} value={city.cidade}>
                                                        {city.cidade}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}



                                    {selectedCity && <Button
                                        disabled={!selectedCity}
                                        fullWidth
                                        variant="contained"
                                        onClick={handleSubmit}
                                        sx={{ fontFamily: 'Roboto' }}
                                    >
                                        {initPoll ? "Enviar Consulta" : "Validar"}

                                    </Button>}
                                </Box>

                            </Box>
                            <Box sx={{ p: 2 }}>
                                {poll.length > 0 ?
                                    <BarChart
                                        xAxis={[{ scaleType: 'band', data: datesPrev }]}
                                        series={chartSeries}
                                        width={1200}
                                        height={400}
                                        slotProps={{
                                            legend: {
                                                direction: 'row',
                                                position: { vertical: 'top', horizontal: 'middle', },
                                                padding: 0,
                                                labelStyle: {
                                                    fontSize: 14,
                                                    fill: 'gray',
                                                },
                                            },
                                        }}
                                        margin={{
                                            left: 40,
                                            right: 20,
                                            top: 85,
                                            bottom: 87,
                                        }}
                                    />
                                    : null
                                }
                            </Box>
                            {poll && poll.length > 0 ?
                                <Box sx={{ p: 2 }}>
                                    <CSVLink data={poll} headers={CSVHeaders} filename={`eleicoes-prefeito-${selectedState}-${selectedCity}-2024.csv`}>
                                        <Typography fontFamily={'Roboto'}>Exportar Dados (CSV)</Typography>
                                    </CSVLink>
                                </Box>
                                : null
                            }
                            <Box sx={{ p: 2 }}>
                                {poll.length > 0 ?
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Candidato</TableCell>
                                                    <TableCell align="center">Partido</TableCell>
                                                    <TableCell align="right">Turno</TableCell>
                                                    <TableCell align="right">Pleito</TableCell>
                                                    <TableCell align="right">Fonte</TableCell>
                                                    <TableCell align="right">Tipo</TableCell>
                                                    <TableCell align="right">Data da Previsão</TableCell>
                                                    <TableCell align="right">ESTIMATIVA</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {poll.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.candidato}
                                                        </TableCell>
                                                        <TableCell align="center">{row.partido}</TableCell>
                                                        <TableCell align="right">{row.turno}</TableCell>
                                                        <TableCell align="right">{row.pleito}</TableCell>
                                                        <TableCell align="right">{row.fonte_estimativa}</TableCell>
                                                        <TableCell align="right">{row.tipo_estimativa}</TableCell>
                                                        <TableCell align="right">{row['data.previsao']}</TableCell>
                                                        <TableCell align="right">{row.estimativa}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    null
                                }
                            </Box>
                        </div>
                    )}
            </Box>
        </div>
    )


}

export default PollManager;
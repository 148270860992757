import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, IconButton, Snackbar, Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TrashIcon from '@mui/icons-material/Delete';
import config from '../../config';
import { SpinnerRoundOutlined } from 'spinners-react';

const TokenManager = () => {

    const accessToken = localStorage.getItem('token');
    const [userTokens, setUserTokens] = useState([]);
    const [noCreditsError, setNoCreditsError] = useState(false);
    const [successCreatedCredits, setSuccessCreatedCredits] = useState(false);
    const [totalTokenCredits, setTotalTokenCredits] = useState(0);
    const [totalUsedTokens, setTotalUsedTokens] = useState(0);
    const [isLoading, setIsLoading] = useState(false);



    const navigate = useNavigate();

    function unsecuredCopyToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }

    const handleCopyTokenClipboard = (text) => {
        if (window.isSecureContext && navigator.clipboard) {
            // Copy the text to clipboard
            navigator.clipboard.writeText(text)
                .then(() => {
                    alert("Text copied to clipboard!");
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                });
        }
        else {
            unsecuredCopyToClipboard(text);
        }

    };

    const handleCreateToken = async () => {
        try {
            setIsLoading(true);
            let data = '';

            let conf = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${config.apiStoreBaseUrl}/api/token/create`,
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                data: data
            };

            axios.request(conf)
                .then((response) => {
                    setIsLoading(false);
                    //const result = JSON.stringify(response.data);
                    setSuccessCreatedCredits(true);
                    refreshTokenList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.status === 403) {
                        setNoCreditsError(true);
                    }
                    console.error(error);
                });

        } catch (error) {
            setIsLoading(false);
            console.error('Create token failed', error);
        }
    }

    const refreshTokenList = () => {
        axios.get(`${config.apiStoreBaseUrl}/api/token/getusertokens`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                }
            })
            .then(response => {
                setUserTokens(response.data);
            })
            .catch(error => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }

                console.error("Error fetching tokens:", error);
            });

        axios.get(`${config.apiStoreBaseUrl}/api/token/totalcredits`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                }
            })
            .then(response => {
                setTotalTokenCredits(response.data.TOTAL_CREDITS);
            })
            .catch(error => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }

                console.error("Error fetching tokens:", error);
            });

        axios.get(`${config.apiStoreBaseUrl}/api/token/totalused`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Pass token in the Authorization header
                }
            })
            .then(response => {
                setTotalUsedTokens(response.data ? response.data.TOTAL_USED_TOKENS : 0);
            })
            .catch(error => {
                if (error.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }

                console.error("Error fetching tokens:", error);
            });



    }

    useEffect(() => {
        refreshTokenList();

    }, [accessToken]);


    const renderSpinner = () => {
        if (isLoading) {
            return (
                <div>
                    <SpinnerRoundOutlined style={{ position: 'absolute', top: '50%', left: '50%' }} сolor='#009EE3' />
                </div>
            )
        }
    }

    return (
        <div>
            {renderSpinner()}
            <Box component="section" sx={{ p: 2 }}>
                <Snackbar
                    open={noCreditsError}
                    autoHideDuration={6000}
                    onClose={() => setNoCreditsError(false)} // Close the alert after a timeout
                >
                    <Alert onClose={() => setNoCreditsError(false)} severity="error">
                        Erro: Sem créditos disponíveis!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={successCreatedCredits}
                    autoHideDuration={6000}
                    onClose={() => setSuccessCreatedCredits(false)} // Close the alert after a timeout
                >
                    <Alert onClose={() => setSuccessCreatedCredits(false)} severity="success">
                        Sucesso: Novo token criado!
                    </Alert>
                </Snackbar>
                <Box sx={{ p: 2, border: '1px dashed grey' }}>
                    <Typography variant="h5">Seus tokens de API</Typography>
                </Box>
                <Box sx={{ p: 4 }}>
                    <Button variant="contained" onClick={handleCreateToken}>Gerar Token</Button>
                    <Typography sx={{ marginTop: 2 }}>Token Disponíveis: {totalTokenCredits - totalUsedTokens}</Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Token</TableCell>
                                    <TableCell>Copiar Token</TableCell>
                                    <TableCell align="center">Data de Criação</TableCell>
                                    <TableCell align="right">Vencimento</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userTokens.map(token => (
                                        <TableRow
                                            key={token.id_token}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body1" component="div"
                                                    sx={{
                                                        whiteSpace: 'nowrap',  // Ensure the text doesn't wrap
                                                        overflow: 'hidden',    // Hide overflow
                                                        textOverflow: 'ellipsis',  // Add ellipsis (three dots)
                                                        maxWidth: '650px',       // Set your desired width
                                                    }}>
                                                    {token.token}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton color="primary" onClick={() => handleCopyTokenClipboard(token.token)}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(token.created).toLocaleString('pt-BR', {
                                                    year: 'numeric',
                                                    month: 'short',  // You can use 'short' for a 3-letter month
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric',
                                                    hour12: false    // To display in 12-hour format with AM/PM
                                                })}
                                            </TableCell>
                                            <TableCell align="right">3h</TableCell>
                                            <TableCell align="right">
                                                <IconButton color="primary">
                                                    <TrashIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>

    )


}

export default TokenManager;
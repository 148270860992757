import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Products from './components/Products';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Register from './components/Register';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const App = () => {
  return (
    <Container maxWidth="false">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          <Router>
              <Routes>
                <Route path="/products" element={<Products />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<Login />} />
              </Routes>
          </Router>
        </Typography>
      </Box>
    </Container>

  );
};

export default App;

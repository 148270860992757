/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import AppTheme from '../shared-theme/AppTheme';
import ColorModeSelect from '../shared-theme/ColorModeSelect';
import { useNavigate } from 'react-router-dom';
import config from '../config';



const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
    padding: 20,
    marginTop: '10vh',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        backgroundImage:
            'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {
            backgroundImage:
                'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
        }),
    },
}));

export default function SignIn(props) {
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    React.useEffect(() => {

        if (token) {
            navigate('/dashboard');
        }
    }, [navigate, token]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (event) => {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        event.preventDefault();
        if (!validateInputs() || !email || !password) {
            return;
        }
        try {
            const response = await axios.post(`${config.apiStoreBaseUrl}/api/auth/login`, { email, password });
            console.log(response)
            localStorage.setItem('token', response.data.token);
            navigate('/dashboard');
        } catch (error) {
            console.error('Register failed', error);
            alert('Invalid login credentials');
        }
    };

    const validateInputs = () => {
        const email = document.getElementById('email');
        const password = document.getElementById('password');

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Digite um email válido.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
            setEmail(email.value);
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('A senha precisa ter no mínimo 6 caracteres.');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
            setPassword(password.value);
        }

        return isValid;
    };

    const handleRegister = () => {
        navigate('/register');
    }

    return (
        <AppTheme {...props}>
            <CssBaseline enableColorScheme />
            <SignInContainer direction="column" justifyContent="space-between">
                <ColorModeSelect sx={{ position: 'fixed', top: '1rem', right: '1rem' }} />
                <Card variant="outlined">
                    <img src='https://www.pollingdata.com.br/img/logo.png' style={{ height: 45, width: 200 }}></img>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="email"><Typography sx={{ fontFamily: 'Roboto' }}>Email</Typography></FormLabel>
                            <TextField
                                error={emailError}
                                helperText={(<font face='Roboto'>{emailErrorMessage}</font>)}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="seu@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{ ariaLabel: 'email', fontFamily: 'Roboto', input: { fontFamily: 'Roboto' } }}
                                onBlur={handleSubmit}

                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password"><Typography sx={{ fontFamily: 'Roboto' }}>Senha</Typography></FormLabel>
                                <Link
                                    component="button"
                                    onClick={handleClickOpen}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline', fontFamily: 'Roboto' }}
                                >
                                    Esqueceu a senha?
                                </Link>
                            </Box>
                            <TextField
                                error={passwordError}
                                helperText={(<font face='Roboto'>{passwordErrorMessage}</font>)}
                                name="password"
                                placeholder="••••••"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                required
                                fullWidth
                                variant="outlined"
                                color={passwordError ? 'error' : 'primary'}
                                onBlur={handleSubmit}
                            />
                        </FormControl>
                        <ForgotPassword open={open} handleClose={handleClose} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                            sx={{ fontFamily: 'Roboto' }}
                        >
                            Entrar
                        </Button>
                        <Typography sx={{ textAlign: 'center', fontFamily: 'Roboto' }}>
                            Não tem cadastro?{' '}
                            <span>
                                <Link
                                    onClick={handleRegister}
                                    variant="body2"
                                    sx={{ alignSelf: 'center', fontFamily: 'Roboto', cursor: 'pointer' }}
                                >
                                    Cadastre-se
                                </Link>
                            </span>
                        </Typography>
                    </Box>
                    <Divider>ou</Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            onClick={() => alert('Sign in with Google')}
                            startIcon={<GoogleIcon />}
                            sx={{ fontFamily: 'Roboto' }}
                        >
                            Entrar com Google
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            onClick={() => alert('Sign in with Facebook')}
                            startIcon={<FacebookIcon />}
                            sx={{ fontFamily: 'Roboto' }}
                        >
                            Entrar com Facebook
                        </Button>
                    </Box>
                </Card>
            </SignInContainer>
        </AppTheme>
    );
}

const env = 'PROD';

let config = null;

if (env === 'PROD') {
    config = {
        apiStoreBaseUrl: 'https://storeapi.pollingdata.com.br',
        apiClientBaseUrl: 'https://api.pollingdata.com.br',
    };

}
else {
    config = {
        apiStoreBaseUrl: 'http://localhost:5000',
        apiClientBaseUrl: 'http://localhost:3000',
    };

}


export default config;